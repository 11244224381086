import { CommonModule } from "@angular/common";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgxFileDropModule } from "ngx-file-drop";
import { ToastrModule } from "ngx-toastr";
import { ConfirmModalComponent } from "./components/confirm-modal/confirm-modal.component";
import { ContractRequestComponent } from "./components/contract-request/contract-request.component";
import { QueryFilterComponent } from "./components/query-filter/query-filter.component";
import { SelectOptionGroupComponent } from "./components/select-option-group/select-option-group.component";
import { TaskViewActivityComponent } from "./components/task-view/task-view-activity/task-view-activity.component";
import { TaskViewHeaderComponent } from "./components/task-view/task-view-header/task-view-header.component";
import { TaskViewInfoComponent } from "./components/task-view/task-view-info/task-view-info.component";
import { TaskViewComponent } from "./components/task-view/task-view.component";
import { UploadDocumnetsModule } from "./components/upload-documents/upload-documents.module";
import { UploadExcelComponent } from "./components/upload-excel/upload-excel.component";
import { AppLoaderComponent } from "./services/app-loader/app-loader.component";
import { AppLoaderService } from "./services/app-loader/app-loader.service";
import { ConfirmationMessageComponent } from "./services/confirmation-message/confirmation-message.component";
import { SendReceiveService } from "./services/send-receive.service";
import { NoDataComponent } from "../shared/components/no-data/no-data";
import {SlNoDirective} from "./utils/appSlNo"
import { SharedService } from "./services/shared.service";
import { RentVariationComponent } from "./components/rent-variation/rent-variation.component";
import { LeaveDetailsComponent } from './components/leave-details/leave-details.component';
import { AppliedFiltersComponent } from './components/applied-filter/applied-filters.component';

import { UploadSchedularComponent } from './components/upload-schedular/upload-schedular.component';
import { EmpAttendanceDetailsComponent } from "./components/emp-attendance-details/emp-attendance-details.component";
import { AddNewItemComponent } from "./add-new-item/add-new-item.component";
import { GenericFormComponent } from './generic-form/generic-form.component';


const modulesToImports = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatCardModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    ToastrModule,
    MatPaginatorModule,
    MatSortModule,
    MatTooltipModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatRadioModule,
    MatSelectModule,
    MatTabsModule,
    MatIconModule,
    MatPaginatorModule,
    UploadDocumnetsModule,
    MatStepperModule,
    NgxFileDropModule
];

@NgModule({
    declarations: [
        AppLoaderComponent,
        TaskViewComponent,
        TaskViewActivityComponent,
        TaskViewHeaderComponent,
        TaskViewInfoComponent,
        UploadExcelComponent,
        ConfirmModalComponent,
        QueryFilterComponent, NoDataComponent,
        ConfirmationMessageComponent,
        ContractRequestComponent,
        RentVariationComponent,SlNoDirective,
        SelectOptionGroupComponent, AppliedFiltersComponent, UploadSchedularComponent, EmpAttendanceDetailsComponent,
        AddNewItemComponent,
        LeaveDetailsComponent,
        GenericFormComponent,
    ],
    imports: modulesToImports,
    exports: [
        AppLoaderComponent,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatPaginatorModule,
        MatSortModule,
        MatTooltipModule,
        MatTabsModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatRadioModule,
        MatSelectModule,
        MatTabsModule,
        MatIconModule,
        MatPaginatorModule,
        TaskViewComponent,
        QueryFilterComponent,
        ConfirmationMessageComponent,
        SelectOptionGroupComponent,
        RentVariationComponent, UploadSchedularComponent
        , NoDataComponent, AppliedFiltersComponent, AddNewItemComponent
    ],
    providers: [
        {
            provide: MatDialogRef,
            useValue: {},
        },
        AppLoaderService,
        SendReceiveService,
        SharedService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
